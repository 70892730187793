<template>
  <div v-if="shouldRender">
    <HtMenuPopupSectionTitle>{{ section }}</HtMenuPopupSectionTitle>
    <div class="grid grid-cols-3 w-[700px]">
      <template v-for="app in apps">
        <HtMenuPopupLink
          v-if="allowedToDisplay(app)"
          :key="app.id"
          :emoji="app.emoji"
          :icon-path="app.iconPath"
          @click="openApp(app)"
        >
          {{ app.name }}
        </HtMenuPopupLink>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { WorkspaceApp } from "../../../shared/types/WorkspaceApp.ts";
import { useAuth } from "../../../stores/auth.js";
import emitter from "../../../shared/services/EventService.ts";
import HtMenuPopupSectionTitle from "../../ui/HtMenuPopupSectionTitle.vue";
import HtMenuPopupLink from "../../ui/HtMenuPopupLink.vue";
import { computed } from "vue";

const props = defineProps<{
  apps: WorkspaceApp[];
  section: string;
}>();

const shouldRender = computed(() => {
  return props.apps.some((app) => allowedToDisplay(app));
});

const auth = useAuth();

function allowedToDisplay(app: WorkspaceApp) {
  if (app.configuration?.permission === undefined) return true;
  return (
    auth.userHasPermission(app.configuration.permission) ||
    auth.userHasPermission(app.configuration.permission.replaceAll(".view", ".preview")) ||
    auth.userHasPermission(app.configuration.permission.replaceAll(".show", ".preview"))
  );
}

function openApp(app: WorkspaceApp) {
  emitter.emit("workspace.openApp", app);
}
</script>

<style scoped></style>
